import React, { useState } from 'react'
import styled from 'styled-components'
import { HeroImageTitle } from 'components/new/hero'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import Layout from 'components/layout'
import Content from 'components/new/content'
import ProductCategoryGrid from 'components/new/product-category-grid'
import BreadcrumbsScript from 'components/scripts/breadcrumbs'
import H2 from 'components/new/typography/h2'
import P from 'components/new/typography/p'
import Spacer from 'components/new/spacer'

const ManitouPage = props => {
  const {
    data: { allSanityManitouEquipmentSubcategory, heroImage },
  } = props

  const [{ breadcrumbs, meta, categoryData }] = useState(() => {
    const breadcrumbs = [
      {
        name: 'Home',
        schemaName: 'Hutson Inc',
        link: '/',
      },
      {
        name: 'Manitou',
        link: '/manitou/',
      },
    ]

    const meta = {
      title: `Manitou | Hutson Inc`,
      description: `Browse Manitou equipment at Hutson.`,
    }

    const categoryData = allSanityManitouEquipmentSubcategory.nodes.map(node => {
      return {
        link: `/manitou/${node.parentCategory.handle}/${node.handle}/`,
        image: node.thumbnail.asset.gatsbyImageData,
        title: node.title,
        imageAlt: `Manitou ${node.title}`,
      }
    })

    return { breadcrumbs, meta, categoryData }
  })
  return (
    <Layout>
      <Helmet>
        <title>{meta.title}</title>
        <meta name='description' content={meta.description} />
      </Helmet>

      <BreadcrumbsScript breadcrumbs={breadcrumbs} />

      <Hero
        title='Manitou'
        image={heroImage.childImageSharp.gatsbyImageData}
        overlayOpacity={0.15}
      />

      <Content kind='full'>
        <H2>Browse our Manitou equipment</H2>
        <Spacer size='m' />
        <ProductCategoryGrid categories={categoryData} />
      </Content>
      <LightBackground>
        <Content kind='full'>
          <H2>Your local Manitou equipment dealer</H2>
          <Spacer size='m' />
          <P>
            At Hutson, Inc., we take pride in being your trusted partner for all your equipment
            needs. As an authorized Manitou equipment dealership, we offer a wide range of
            high-quality machinery designed to enhance productivity and efficiency across various
            industries. Whether you're in construction, agriculture, or material handling, our team
            of experts is here to assist you in finding the perfect solution. We provide top-notch
            equipment, including forklifts, telehandlers, mobile elevated work platforms and
            articulated loaders, backed by exceptional service and support. Explore our inventory
            and experience the Hutson difference today!
          </P>
        </Content>
      </LightBackground>
    </Layout>
  )
}

const Hero = styled(HeroImageTitle)`
  height: 400px;
`

const CenteredH2 = styled(H2)`
  text-align: center;
`

const StyledProductCategoryGrid = styled(ProductCategoryGrid)`
  display: flex;
  justify-content: center;
`

const LightBackground = styled.div`
  background-color: ${props => props.theme.color.n20};
`

export const pageQuery = graphql`
  query manitouPage {
    heroImage: file(relativePath: { eq: "manitou/manitou-hero.jpg" }) {
      ...FullWidthImage
    }
    allSanityManitouEquipmentSubcategory {
      nodes {
        handle
        parentCategory {
          handle
        }
        thumbnail {
          asset {
            gatsbyImageData(width: 240)
          }
        }
        title
      }
    }
  }
`

export default ManitouPage
